import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, BehaviorSubject, of, Subscription} from 'rxjs';
import {map, catchError, switchMap, finalize} from 'rxjs/operators';
import {AuthModel} from '../../interfaces/auth.model';
import {Router} from '@angular/router';
import {IWebUser, RutasRest} from "@zabalit/j06-auditoria-lopd-shared";
import {CONFIG_APP_TOKEN} from "../../../../core/constants/config-app-token";
import {IConfigApp} from "../../../../core/interfaces/config-app";
import {HttpClient} from "@angular/common/http";


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private static MILIS_AUTH_CACHE = 15000;
  private authLocalStorageToken = `token`;
  private milisAuthCache: number;
  currentUser$: Observable<IWebUser | null>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<IWebUser | null>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): IWebUser | null {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: IWebUser | null) {
    this.currentUserSubject.next(user);
  }

  constructor(
    @Inject(CONFIG_APP_TOKEN) private configApp: IConfigApp,
    private router: Router,
    private http: HttpClient,

  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<IWebUser | null>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    // const subscr = this.getUserByToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  getBaseUrl(): string {
    return this.configApp.urlApi + RutasRest.AUTH + '/web-users';
  }

  getUser(): Observable<IWebUser|null> {
    const authUser = this.getAuthFromLocalStorage();
    const milisActual = new Date().getTime();
    if (!authUser || !this.milisAuthCache || this.milisAuthCache + AuthService.MILIS_AUTH_CACHE < milisActual) {
      return this.authenticate();
    }
    this.currentUserSubject.next(authUser);
    return of(authUser);
  }

  authenticate(): Observable<IWebUser|null> {
    this.isLoadingSubject.next(true);
    return this.http.get<IWebUser|null>(this.getBaseUrl() + '/me').pipe(
      catchError(() => of(null)),
      switchMap((user: IWebUser|null) => {
        if (user) {
          this.setAuthFromLocalStorage(user);
          return of(user);
        } else {
          this.router.navigate(['auth', 'login']);
          return of(null);
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // private methods
  private setAuthFromLocalStorage(authUser: IWebUser): boolean {
    localStorage.setItem(this.authLocalStorageToken, JSON.stringify(authUser));
    this.currentUserSubject.next(authUser);
    this.milisAuthCache = new Date().getTime();
    return false;
  }

  private getAuthFromLocalStorage(): IWebUser | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}

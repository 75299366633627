import {Directive, ElementRef, TemplateRef, ViewContainerRef} from '@angular/core';
import {IWebUser, RolesWebUser} from "@zabalit/j06-auditoria-lopd-shared";
import {AuthService} from "../../../modules/auth";

@Directive({
  selector: '[esAdmin]',
  standalone: true
})
export class EsAdminDirective {

  authUser: IWebUser;

  constructor(
    private authService: AuthService,
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
    console.log('Uso')
    this.obtenerAuthUser();
  }


  private obtenerAuthUser(): void {
    this.authService.getUser().subscribe(authUser => {
      this.authUser = authUser!;
      this.actualizarVista();
    });
  }

  private actualizarVista(): void {
    if (this.checkPermisos()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermisos(): boolean {
    return !(!this.authUser || this.authUser.rol !== RolesWebUser.ADMIN);
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirmar-borrar',
  templateUrl: './modal-confirmar-borrar.component.html',
  styleUrls: ['./modal-confirmar-borrar.component.scss']
})
export class ModalConfirmarBorrarComponent implements OnInit {

  @Input() elemento: string;
  valorIntroducir: string;
  valorComprobar: string;

  constructor(
      private ngbActiveModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.valorComprobar = this.generarClaveSeguridad();
  }

  generarClaveSeguridad(): string {
    return Math.random().toString(36).substr(2, 5);
  }

  borrar(): void  {
    this.ngbActiveModal.close(true);
  }

  cerrar(): void  {
    this.ngbActiveModal.close(false);
  }

}


<div class="d-flex justify-content-between align-items-center">
    <ul class="pagination">
        <li class="page-item" [class.active]="num === pageSize" *ngFor="let num of ranges">
            <a class="page-link" (click)="changePageSize(num)">{{num}}</a>
        </li>
    </ul>
    <ngb-pagination [page]="$any(currentPage)" [pageSize]="$any(pageSize)" [collectionSize]="total" [maxSize]="maxSize" (pageChange)="changeCurrentPage($event)" [boundaryLinks]="true">
        <ng-container *ngIf="!hideFirst"><ng-template ngbPaginationFirst>{{'pagination.PRIMERO' | translate}}</ng-template></ng-container>
        <ng-container *ngIf="!hideLast"><ng-template ngbPaginationLast>{{'pagination.ULTIMO' | translate}}</ng-template></ng-container>
        <ng-container *ngIf="!hidePrevious"><ng-template ngbPaginationPrevious>{{'pagination.ANTERIOR' | translate}}</ng-template></ng-container>
        <ng-container *ngIf="!hideNext"><ng-template ngbPaginationNext>{{'pagination.SIGUIENTE' | translate}}</ng-template></ng-container>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
</div>


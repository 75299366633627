import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeMilis'
})
export class TimeMilisPipe implements PipeTransform {

  transform(milis: number, corto: 'h' | 'm' | 's' | undefined = undefined): string {
    const seconds = Math.floor((milis / 1000) % 60);
    const minutes = Math.floor((milis / (1000 * 60)) % 60);
    const hours = Math.floor((milis / (1000 * 60 * 60)) % 24);

    if (corto) {
      if (corto === 'h') {
        return `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
      } else if (corto === 'm') {
        return `${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
      } else {
        return `${seconds > 9 ? seconds : '0' + seconds}`;
      }
    }

    if (hours > 0) {
      return `${hours}h, ${minutes}m y ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m y ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  }

}

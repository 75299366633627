import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import {AuthService} from '../auth.service/auth.service';
import {catchError, map, Observable, of} from "rxjs";
import {RolesWebUser} from "@zabalit/j06-auditoria-lopd-shared";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(route.data?.roles);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.check(route.data?.roles);
  }

  private check(roles?: Array<RolesWebUser>): Observable<boolean> {
    return this.authService.getUser().pipe(
      map((user) => {
        if (!user) {
          this.router.navigate(['/auth/login']);
          return false;
        }
        return !roles || roles.includes(user.rol);
      }),
      catchError(() => {
        this.router.navigate(['/auth/login']);
        return of(false);
      }),
    )
  }
}

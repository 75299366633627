import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tabla-sin-datos',
  templateUrl: './tabla-sin-datos.component.html',
  styleUrls: ['./tabla-sin-datos.component.scss']
})
export class TablaSinDatosComponent implements OnInit {

  @Input() traduccion: string = 'generales.NO_HAY_RESULTADOS';

  constructor() {
  }

  ngOnInit(): void {
  }

}

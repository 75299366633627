import {Component, OnInit, Input} from '@angular/core';
import {IFormErrorMessage} from '../../interfaces/form-error-message';
import {TranslateService} from '@ngx-translate/core';
import {TiposInputValidator} from '../../enums/tipos-input-validator';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent implements OnInit {

  @Input() control: AbstractControl;
  // opcionalmente se pueden pasar mensajes de error personalizados para cada tipo de error
  @Input() mensajes: Array<IFormErrorMessage>;

  messageErrors: Array<IFormErrorMessage> = [
    {validator: TiposInputValidator.REQUIRED, translate: 'formErrors.ERR_REQUIRED'},
    {validator: TiposInputValidator.EMAIL, translate: 'formErrors.ERR_EMAIL'},
    {validator: TiposInputValidator.DATE, translate: 'formErrors.ERR_DATE'},
    {validator: TiposInputValidator.DNI, translate: 'formErrors.ERR_DNI'},
    {validator: TiposInputValidator.CRON, translate: 'formErrors.ERR_CRON'},
    {validator: TiposInputValidator.NUMBER, translate: 'formErrors.ERR_NUMBER'},
    {validator: TiposInputValidator.PASSWORDSEQUALS, translate: 'formErrors.ERR_PASSWORD_EQUALS'},
    {validator: TiposInputValidator.MINLENGTH, translate: 'formErrors.ERR_MIN_LENGTH'},
    {validator: TiposInputValidator.MAXLENGTH, translate: 'formErrors.ERR_MAX_LENGTH'},
    {validator: TiposInputValidator.MAX, translate: 'formErrors.ERR_MAX'},
    {validator: TiposInputValidator.MIN, translate: 'formErrors.ERR_MIN'},
    {validator: TiposInputValidator.EMAILREPETIDO, translate: 'formErrors.ERR_EMAIL_REPETIDO'},
    {validator: TiposInputValidator.FECHAMINIMA, translate: 'formErrors.ERR_FECHA_MINIMA'},
    {validator: TiposInputValidator.CUSTOMVISIBLE, customTranslate: true},
    {validator: TiposInputValidator.CUSTOM, customTranslate: true}
  ];


  constructor(
      private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    if (this.mensajes && this.mensajes.length > 0) {
      this.mensajes.forEach((objMensaje) => {
        const mensajeASustituir = this.messageErrors.find((objMensajeError) => {
          return objMensajeError.validator === objMensaje.validator;
        });

        if (mensajeASustituir) {
          mensajeASustituir.translate = objMensaje.translate;
        } else {
          this.messageErrors.push(objMensaje);
        }
      });
    }
  }

  getCurrentErrors(): Array<IFormErrorMessage> {
    return this.messageErrors.filter(me => this.control.errors?.hasOwnProperty(me.validator));
  }

  crearMensajeError(formErrorMessage: IFormErrorMessage): string {

    let traduccion: string = formErrorMessage.translate as string;

    if (formErrorMessage.customTranslate && this.control.errors) {
      traduccion = this.control.errors[formErrorMessage.validator].translate;
    }

    return this.translateService.instant(traduccion);
  }

}



import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {PaginationComponent} from "./components/pagination/pagination.component";
import {TablaCargandoComponent} from "./components/tabla-cargando/tabla-cargando.component";
import {InlineSVGModule} from "ng-inline-svg-2";
import {TimeMilisPipe} from "./pipes/time-milis.pipe/time-milis.pipe";
import {NgApexchartsModule} from "ng-apexcharts";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EsAdminDirective} from "./directives/es-admin/es-admin.directive";
import {ToastrModule} from "ngx-toastr";
import {NgbDatepickerModule, NgbNavModule, NgbPaginationModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {FormErrorsComponent} from "./components/form-errors/form-errors.component";
import {ModalConfirmarBorrarComponent} from "./components/modal-confirmar-borrar/modal-confirmar-borrar.component";
import {TablaSinDatosComponent} from "./components/tabla-sin-datos/tabla-sin-datos.component";


@NgModule({
  declarations: [
    PaginationComponent,
    FormErrorsComponent,
    TimeMilisPipe,
    ModalConfirmarBorrarComponent,
    TablaSinDatosComponent,
    TablaCargandoComponent,
  ],
  imports: [
    CommonModule,
    EsAdminDirective,
    NgApexchartsModule,
    InlineSVGModule,
    NgbTooltipModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    ToastrModule
  ],
  exports: [
    TimeMilisPipe,
    EsAdminDirective,
    PaginationComponent,
    TablaCargandoComponent,
    NgbNavModule,
    FormErrorsComponent,
    ModalConfirmarBorrarComponent,
    TablaSinDatosComponent,
    NgApexchartsModule,
    NgbTooltipModule,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule,
    TranslateModule,
    NgbPaginationModule,
    NgbDatepickerModule,
  ]
})
export class SharedModule {
}

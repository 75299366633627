export enum TiposInputValidator {
  REQUIRED = 'required',
  EMAIL = 'email',
  JSON = 'json',
  DATE = 'date',
  BSDATE = 'bsdate',
  IP = 'ip',
  DNS = 'dns',
  URL = 'url',
  DNI = 'dni',
  CRON = 'cron',
  PHONE = 'phone',
  NUMBER = 'number',
  PASSWORDSEQUALS = 'passwordsequals',
  MINLENGTH = 'minlength',
  MAXLENGTH = 'maxlength',
  MAX = 'max',
  MIN = 'min',
  EMAILREPETIDO = 'emailrepetido',
  CODIGOPOSTAL = 'codigopostal',
  VALORREPETIDO = 'valorrepetido',
  JSONPARSE = 'jsonparse',
  RANGOFECHAS = 'rangofechas',
  FECHAMINIMA = 'fechaminima',
  CUSTOMVISIBLE = 'customvisible',
  CUSTOM = 'custom',
  PATTERN = 'pattern'
}

<div class="modal-header">
    <h4 class="modal-title">{{'acciones.BORRAR' | translate}}  {{elemento | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="cerrar()"></button>
</div>
<div class="modal-body">
    <div class="form-group">
        <label>
            {{'generales.PREG_RELALIZAR_ACCION' | translate}}
            <div [innerHtml]="('generales.INFO_CODIGO_BORRAR' | translate: {codigo: valorComprobar})"></div>
        </label>
        <input type="text" class="form-control mt-4" [placeholder]="'generales.CODIGO' | translate" [(ngModel)]="valorIntroducir">
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white btn-active-light-primary btn-sm" (click)="cerrar()">{{'acciones.CANCELAR' | translate}}</button>
    <button type="button" class="btn btn-danger btn-sm"  [disabled]="valorIntroducir !== valorComprobar" (click)="borrar()">{{'acciones.BORRAR' | translate}}</button>
</div>

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CONFIG_APP_TOKEN} from "./core/constants/config-app-token";
import {environment} from "../environments/environment";
import {AuthInterceptor} from "./modules/auth/services/auth.interceptor/auth.interceptor";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {SharedModule} from "./shared/shared.module";
import {ToastrModule} from "ngx-toastr";
import {MarkdownModule} from "ngx-markdown";

// #fake-end#

// function appInitializer(authService: AuthService) {
//   return () => {
//     return new Promise((resolve) => {
//       //@ts-ignore
//       authService.getUserByToken().subscribe().add(resolve);
//     });
//   };
// }

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    MarkdownModule.forRoot({}),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      autoDismiss: false,
      preventDuplicates: true
    }),
    AppRoutingModule,
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: CONFIG_APP_TOKEN,
      useValue: environment
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [AuthService],
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

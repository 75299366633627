import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() currentPage?: number;
  @Input() pageSize?: number;
  @Input() total: number;
  @Input() maxSize: number = 5;
  @Input() hideFirst: boolean;
  @Input() hideLast: boolean;
  @Input() hidePrevious: boolean;
  @Input() hideNext: boolean;
  @Input() ranges: Array<number> = [10, 25, 50, 100, 250];
  @Output() onChangeCurrentPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() onChangePageSize: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  changeCurrentPage(currentPage: number) {
    this.onChangeCurrentPage.next(currentPage);
  }

  changePageSize(pageSize: number) {
    this.onChangePageSize.next(pageSize);
  }
}

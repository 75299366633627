// Spain
export const locale = {
  lang: 'es-ES',
  data: {
    generales: {
      NOMBRE: "Nombre",
      HOME: "Dashboard",
      USUARIO: "Usuario",
      IDIOMA: "Idioma",
      FILTRO: "Filtro",
      TOTAL: "Total",
      NO_HAY_RESULTADOS: "No hay resultados",
      CORRECTO: "Correcto",
      CARGANDO: "Cargando...",
      AVISO: "Aviso",
      ERROR: "Error",
      CONSULTAS: "Consultas",
      REGISTROS: "Registros",
      BUSCAR_WEB_USERS: "Buscar usuarios Web",
      ERROR_ACCESO: "Error de acceso",
      IMPOSIBLE_ACCEDER_APP: "No se puede acceder a la aplicación, Contacte con el administrador",
      ROL: "Rol",
      CODIGO: "Código",
      INICIO: "Inicio",
      FIN: "Fin",
      APELLIDO_1: "Primer apellido",
      APELLIDO_2: "Segundo apellido",
      PALABRAS: "Palabras",
      ERROR_SISTEMA: "Error del sistema",
      FECHA_ALTA: "Fecha alta",
      FECHA_MODIFICACION: "Fecha modificación",
      BUSCAR_POR_FECHA: "Buscar por fecha...",
      BUSCAR_POR_ESTADO: "Buscar por estado...",
      BUSCAR_POR_NOMBRE: "Buscar por nombre...",
      BUSCAR_POR_AWS_JOB: "Buscar por Aws Job...",
      AWS_JOB: "Aws Job",
      PREG_RELALIZAR_ACCION: "¿Está seguro de que deseas realizar esta acción?",
      INFO_CODIGO_BORRAR: "Introduce el código <b>{{codigo}}</b> para continuar",
      ERROR_NO_ENCONTRADO: "Ups!",
      INFO_ERROR_NO_ENCONTRADO: "Página no encontrada",
      INFO_ERROR_SISTEMA: "Algo ha ido mal, intentalo mas tarde",
      VOLVER_INICIO: "Volver a inicio",
      EN_CURSO: "En curso",
      CERRAR_SESION: "Cerrar sesion",
      INFORMACION: "Información",
      WEB_USERS: "Usuarios",
      WEB_USER: "Usuario",
      ID: "Id.",
      CONFIG: "Configuración",
      FECHA_INICIO: "Fecha inicio",
      FECHA_FIN: "Fecha fin",
      ESTADO: "Estado",
      DESCONOCIDO: "Desconocido",
      FECHA: "Fecha",
      MI_PERFIL: "Mi perfil",
    },
    estadosSessionRecord: {
      TRANSCRIBIENDO: 'Transcribiendo...',
      EN_COLA: 'En cola...',
      FINALIZADO: 'Finalizado'
    },
    estadosProcesoTranscripcion: {
      TRANSCRIBIENDO: 'Transcribiendo',
      FINALIZADO: 'Finalizado'
    },
    rolesWebUser: {
      USUARIO: 'Usuario',
      ADMIN: 'Administrador',
    },
    pagination: {
      PRIMERO: "Primero",
      ULTIMO: "Ultimo",
      SIGUIENTE: "Siguiente",
      ANTERIOR: "Anterior",
    },
    idiomas: {
      "eu-ES": "Euskera",
      "es-ES": "Castellano",
    },
    acciones: {
      CREAR: "Crear",
      CANCELAR: "Cancelar",
      GUARDAR: "Guardar",
      CERRAR: "Cerrar",
      PROBAR: "Probar",
      BORRAR: "Borrar",
      EDITAR: "Editar"
    },
    nombresTask: {
      TRANSCRIBE_TASK: "Tarea de transcripción"
    },
    warns: {
      INFO_FORM_INVALIDO: "El formulario no es válido, revisa los datos"
    },
    errors: {
      ERR_BORRAR_DATOS: "Error al borrar los datos",
      ERR_OBTENER_DATOS: "Error al obtener los datos",
      ERR_GUARDAR_DATOS: "Error al guardar los datos"
    },
    formErrors: {
      ERR_REQUIRED: "El campo es obligatorio",
      ERR_USUARIO_EXISTE: "Este usuario ya existe",
      ERR_MAC: "El campo tiene que ser una Mac válida",
      ERR_EMAIL: "El campo tiene que ser un email",
      ERR_DATE: "El campo tiene que ser una fecha",
      ERR_CRON: "El campo tiene que ser una expresión cron válida",
      ERR_NUMBER: "El campo tiene que ser un número",
      ERR_MAX_LENGTH: "Máximo de caracteres superado",
      ERR_MIN_LENGTH: "Mínimo de caracteres no superado",
      ERR_MIN: "El valor numérico es demasiado pequeño",
      ERR_MAX: "El valor numérico es demasiado grande",
      ERR_USUARIO_REPETIDO: "Este usuario ya está en uso"
    }
  }
};
